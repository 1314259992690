<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
			ref="list"
        >
			<template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加商品</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">上架</span>
                <span v-else-if="data.text=='0'">下架</span>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
                </span>
            </template>
        </TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
		</EditPop>
    </div>
</template>

<script>
import TableList from '@/components/TableList'
import { getMaterielList,materielDelete ,changeMateriel,brandSelect} from '@/api/goods'
import EditPop from "@/components/EditPop";
const columns = [
    {
        title: "品项名称",
        dataIndex: "goods_title",
    },
    {
        title: "编码",
        dataIndex: "goods_code",
    },
    {
        title: "型号",
        dataIndex: "goods_model",
    },
    {
        title: "押金",
        dataIndex: "goods_price",
    },
    {
        title: "库存",
        dataIndex: "goods_stock",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "备注",
        dataIndex: "remarks",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
		TableList,
		EditPop
    },
    data() {
        return {
            get_table_list: getMaterielList,
            columns,
            brand_data:[],
            form_data_seo: {
                list: [
					
                    {
                        type: "text",
                        name: "title",
                        title: "商品",
                        placeholder: '商品名称/条形码',
                        options: {

                        }
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
			},
			form_data:{
				title: '添加',
                list: [
                    {
                        type: "select",
                        name: "brand_id",
                        title: "品牌",
                        mode: "default",
                        options: {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: "请选择品牌"
                                }
                            ],
                        },
                        list: []
                    },
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'goods_title',
                        title: '商品名称',
                        options: {},
					},
					{
                        type: 'text',
                        name: 'goods_code',
                        title: '商品条码',
                        options: {},
					},
					{
                        type: 'text',
                        name: 'goods_model',
                        title: '型号',
                        options: {},
					},
					{
                        type: 'text',
						name: 'goods_price',
						placeholder:'请输入数字',
                        title: '押金',
                        options: {},
					},
					{
                        type: 'text',
						name: 'goods_stock',
						placeholder:'请输入数字',
                        title: '库存',
                        options: {},
                    },
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: [
                            {
                                key: 1,
                                value: "上架"
                            },
                            {
                                key: 0,
                                value: "下架"
                            }
                        ],
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remarks',
                        title: '备注',
                        options: {},
                    },
                ]
			},
			visible:false,
        }
	},
    async created() {
        this.getBrand();
    },
	methods:{
		add(){
			this.form_data.title = "添加"
			this.form_data.list.forEach(item => {
                item.options.initialValue = '';
			})
			this.visible = true;
		},
		edit(record){
			this.form_data.title = "编辑"
			let data = record
            this.form_data.list.forEach(item => {
                item.options.initialValue = data[item.name];
            })
            this.visible = true;
		},
        getBrand() {
            brandSelect().then(res=>{
                this.form_data.list.forEach(item => {
                if (item.name == "brand_id") {
                        item.list = res.data.list.map((item) => {
                            let val = {};
                            val.key = item.value;
                            val.value = item.label
                            return val
                        });
                    }
                });
            })
            
        },
		submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            changeMateriel({
                data: {
                    ...data,
				},
				info:true
            }).then(res => {
				this.visible = false;
				this.$refs.list.get_list();
            })

        },
		del(record) {
            materielDelete({
                data: {
                    id: record.id
				},
				info:true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
	}
}
</script>
<style type="text/css" scoped>
	.slot_test {
		display: flex;
	}
	.barnd_name{
		flex: 2;
	}
	.barnd_rt{
		flex: 1;
		margin-left: 10px;
	}
</style>